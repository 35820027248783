import './src/styles/global.css'
import Layout from "./src/components/Layout";
import React from "react";

const transitionDelay = 500;

export function wrapPageElement({element, props}) {
    return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({
                                       routerProps: {location},
                                       getSavedScrollPosition
                                   }) => {
    window.history.scrollRestoration = "manual"
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else {
/*
        const savedPosition = getSavedScrollPosition(location);
*/
        window.setTimeout(
            () => window.scrollTo(0, 0),
            transitionDelay
        );
    }
    return false;
};