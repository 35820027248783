"use client";
import React, { useState } from "react";
import {Link} from "gatsby";
import { motion } from "framer-motion";
import { navigate } from "gatsby"

export default function Navigation() {

    const [isOpen, setIsOpen] = useState(false);

    const links = [
        {
            title: "Accueil",
            url: "/",
        },
        {
            title: "Projets",
            url: "/projets",
        },
        {
            title: "À propos",
            url: "/apropos",
        },
        {
            title: "Contact",
            url: "/contact",
        },
    ];
    const motionButton = {
        open:{
            rotate: "135deg",
            transition:{
                ease: "backInOut",
                duration:0.7,
            }
        },
        closed:{
            rotate: "0deg",
            transition:{
                ease: "backInOut",
                duration:0.7,
            }
        }
    }
    const motionNav = {
        open: {
            x: "0",
            transition: {
                duration: 0.6,
                ease: [0.83, 0, 0.17, 1],
            },
        },
        closed: {
            x: "-100%",
            transition: {
                delay:0.1,
                duration: 0.6,
                ease: [0.83, 0, 0.17, 1],
            },
        },
    };
    const motionUl = {
        open: {
            transition: {  delayChildren: 0.4, staggerChildren: 0.04 },
        },
        closed: {
            transition: { staggerChildren: 0.03, delayChildren: 0.1 },
        },
    };
    const motionLinks = {
        open: {
            x: "0",
            opacity:1,
            transition: {
                y: {
                    type:"spring",
                    stiffness: 300,
                    damping: 24,
                    ease: [0.5, 1, 0.89, 1],
                },
            },
        },

        closed: {
            x: "-150px",
            opacity:0,
            transition: {
                y: {
                    duration: 0.4,
                },
            },
        },
    };

    function handleClick(event, link) {
        event.preventDefault();
        setIsOpen(!isOpen);
        setTimeout(() => {
            navigate(link)
        }, "400");
    }

    return (
        <>
            <div className="container-normal">
                <div className="relative pt-8 pb-8">
                    <div className="flex">
                        <Link to="/">
                            <img src="/Logo.svg" style={{height:"60px"}} alt="Studio Claqué Logo" />
                        </Link>
                    </div>
                    <div className="absolute right-0 top-8" style={{height:"50px", width:"50px"}}>
                        <div className="mix-blend-difference fixed z-50">
                            <motion.button animate={isOpen ? "open" : "closed"} initial={false} variants={motionButton} onClick={() => setIsOpen(!isOpen)}>
                                <svg height="50px" width="50px" className="fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                                    <path d="M7.3 0h1.4v7.3H16v1.4H8.7V16H7.3V8.7H0V7.3h7.3V0Z"></path>
                                </svg>
                            </motion.button>
                        </div>
                    </div>

                </div>
            </div>
            <motion.nav
                animate={isOpen ? "open" : "closed"}
                initial={false}
                variants={motionNav}
                className="fixed z-30 inset-0 bg-light text-dark-950 text-sm overflow-hidden flex justify-center items-center overflow-y-auto"
            >
                <div className="text-center py-10 m-auto">
                    <motion.ul className="text-center flex gap-y-8 flex-col" variants={motionUl}>
                        {links.map((link,index) => (
                            <motion.li
                                key={index}
                                variants={motionLinks}
                            >
                                <a
                                    href={link.url}
                                    onClick={event => handleClick(event,link.url)}
                                    className="navigation-link"
                                >
                                    {link.title}
                                </a>
                            </motion.li>
                        ))}
{/*                        <motion.ul

                            className="flex font-medium text-xl text-dark-400 gap-x-4 justify-center">
                            <li>Instagram</li>
                            <li>Linkedin</li>
                        </motion.ul>*/}
                    </motion.ul>
                </div>
            </motion.nav>
        </>
    );
}
