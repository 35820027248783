import * as React from "react";
export default function Footer() {
    const socialLinks = [
        {
            title: 'Instagram',
            url: 'https://www.instagram.com/studioclaque/'
        },
        {
            title: 'Linkedin',
            url: 'https://www.linkedin.com/company/studio-claqu%C3%A9/'
        }
    ]
    return (
        // TODO: intégrer les mentions légales
        <footer className="grid grid-cols-2 md:grid-cols-3 items-center pt-20 pb-10">
            <div className="text-zinc-800">
                <p className="font-medium">©2023 studio claqué</p>
            </div>
            <div className="flex justify-end md:justify-center">
                <svg className="fill-dark-950" width="60px" id="uuid-51a3dee8-7bb2-48c7-bcad-7ac5bbdefdd0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 319.03 111.94"><path d="m86.49,111.94h-2.44c-34.13,0-51.78-15.41-51.78-44.66,0-21.16,9.95-35.01,27.5-41.93l-.78-1.95c-11.8,3.51-20.48,4.97-31.2,4.97H0V0h136.52v28.67c-5.66-.1-14.14-1.85-24.86-5.17l-.68,2.05c17.65,6.92,27.4,20.67,27.4,41.73,0,29.25-17.84,44.66-51.88,44.66Zm.49-83.57h-3.51c-22.13,0-34.03,9.85-34.03,28.18s11.31,26.72,33.54,26.72h4.49c22.13,0,33.35-9.17,33.35-26.72s-11.8-28.18-33.84-28.18Z"/><path d="m87.07,17.16h-2.76c-17.4,0-26.74,7.74-26.74,22.15s8.89,21,26.36,21h3.52c17.4,0,26.21-7.2,26.21-21s-9.27-22.15-26.59-22.15Z"/><path d="m180.66,67.28c0-21.06,9.75-34.81,27.4-41.73l-.68-2.05c-10.73,3.32-19.21,5.07-24.86,5.17V0h136.52v28.38h-27.79c-10.73,0-19.4-1.46-31.2-4.97l-.78,1.95c17.55,6.92,27.5,20.77,27.5,41.93,0,29.25-17.65,44.66-51.78,44.66h-2.44c-34.03,0-51.88-15.41-51.88-44.66Zm17.55-10.73c0,17.55,11.21,26.72,33.35,26.72h4.49c22.23,0,33.54-9.17,33.54-26.72s-11.9-28.18-34.03-28.18h-3.51c-22.04,0-33.84,9.85-33.84,28.18Z"/><path d="m205.36,39.31c0,13.79,8.81,21,26.21,21h3.52c17.47,0,26.36-7.2,26.36-21s-9.35-22.15-26.74-22.15h-2.76c-17.32,0-26.59,7.74-26.59,22.15Z"/></svg>
            </div>
            <div>
                <ul className="flex justify-normal md:justify-end font-medium text-zinc-800 gap-x-4">
                    {socialLinks.map((link, index) => (
                        <a key={index} href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
                    ))}
                </ul>
            </div>
        </footer>
    );
}
