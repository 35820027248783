exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apropos-js": () => import("./../../../src/pages/apropos.js" /* webpackChunkName: "component---src-pages-apropos-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projets-belladrinks-js": () => import("./../../../src/pages/projets/belladrinks.js" /* webpackChunkName: "component---src-pages-projets-belladrinks-js" */),
  "component---src-pages-projets-js": () => import("./../../../src/pages/projets.js" /* webpackChunkName: "component---src-pages-projets-js" */),
  "component---src-pages-projets-radium-js": () => import("./../../../src/pages/projets/radium.js" /* webpackChunkName: "component---src-pages-projets-radium-js" */),
  "component---src-pages-projets-roussetavocats-js": () => import("./../../../src/pages/projets/roussetavocats.js" /* webpackChunkName: "component---src-pages-projets-roussetavocats-js" */)
}

