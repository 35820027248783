import * as React from "react";
import Navigation from "./Navigation";
import Footer from "./Footer"
import {motion, AnimatePresence} from "framer-motion";
export default function Layout({children, location}) {
const duration = 0.5
const variants = {
    initial: {
        opacity: 0,
        y: 20
    },
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        transition: {
            duration: duration,
            delay: duration,
            when: "beforeChildren",
        },
    },
    exit: {
        opacity: 0,
        y: 20,
        transition: { duration: duration },
    },
}
    return(
        <>
            <Navigation />
            <AnimatePresence>
                <motion.main
                    key={location.pathname}
                    variants={variants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    {children}
                    <div className="container-normal">
                        <Footer />
                    </div>
                </motion.main>
            </AnimatePresence>
        </>

    )
}